import React from 'react'
import { Helmet } from 'react-helmet'
import { helmetJsonLdProp } from 'react-schemaorg'
import { Product } from 'schema-dts'

import { ProductPage } from '../../types/productPageV2'
import { useSKU } from '../../hooks/useSKUs'
import useBrand from '../../hooks/useBrand'
import useUrlPathBuilder from '../../hooks/useUrlPathBuilder'

interface Props {
  landingPage: ProductPage
}

const ProductPageSEO: React.FC<Props> = ({ landingPage }) => {
  const brand = useBrand()
  const { urlPathBuilder, baseURL } = useUrlPathBuilder()
  const {
    product: {
      productPackage: { items, planCode },
    },
  } = landingPage
  const sku = useSKU(items, planCode)
  return (
    <Helmet
      script={[
        helmetJsonLdProp<Product>({
          '@id': urlPathBuilder([], {
            baseURL,
            hash: 'product',
          }),
          '@context': 'https://schema.org',
          '@type': 'Product',
          name: sku?.plan_name,
          description: landingPage.product.sellingPoints.map(
            (sp) => sp.sellingPoint
          ),
          sku: sku?.plan_code,
          brand: {
            '@type': 'Brand',
            name: brand.name,
          },
          offers: {
            '@type': 'Offer',
            priceCurrency: sku?.currency_code,
            price: sku?.amount,
            availability: 'https://schema.org/InStock',
            itemCondition: 'https://schema.org/NewCondition',
          },
        }),
      ]}
    />
  )
}

export default ProductPageSEO
